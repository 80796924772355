<template>
    <v-container>
        <v-form ref="form" v-model="valid">
            <v-row>
                <v-col cols="12" sm="5" md="4" lg="4">
                    <v-file-input
                        label="Imposta Immagine"
                        show-size
                        accept=".png, .jpg, .jpeg"
                        small-chips
                        :rules="requiredFileRules"
                        v-model="currentPromo.imageFile"
                        prepend-icon="mdi-camera"
                        required
                    >
                    </v-file-input>

                    <v-file-input
                        label="Imposta Audio"
                        show-size
                        prepend-icon="mdi-music"
                        accept="audio/mpeg"
                        small-chips
                        :rules="audioValidation"
                        v-model="currentPromo.audioFile"
                    ></v-file-input>

                    <v-file-input
                        label="Imposta Video"
                        show-size
                        prepend-icon="mdi-video"
                        accept="video/mp4"
                        small-chips
                        :rules="videoValidation"
                        v-model="currentPromo.videoFile"
                    ></v-file-input>

                    <v-file-input
                        label="Imposta Allegato"
                        show-size
                        accept=".png, .jpg, .jpeg, .pdf"
                        small-chips
                        :rules="fileRules"
                        v-model="currentPromo.attachFile"
                    >
                    </v-file-input>
                    <v-switch
                        v-model="publishDateCustom"
                        :label="
                            publishDateCustom
                                ? 'Personalizza Pubblicazione'
                                : 'Personalizza Pubblicazione'
                        "
                    ></v-switch>
                </v-col>
                <v-col cols="12" sm="7" md="8" lg="8">
                    <!-- <v-select
                        :items="items"
                        item-text="desc"
                        item-value="id"
                        label="Categoria"
                        outlined
                        v-model="currentPromo.Type"
                        required
                    >
                    </v-select> -->
                    <v-row>
                        <v-col>
                            <PromoPrioritySelector
                                :model.sync="currentPromo.Priorita"
                            ></PromoPrioritySelector>
                        </v-col>
                        <v-col v-if="farms === null">
                            <PromoCategorySelector
                                :farmId="currentPromo.FarmId"
                                :model.sync="currentPromo.Type"
                            ></PromoCategorySelector>
                        </v-col>
                        <v-col v-else>
                            <PromoCategorySelectorLocal
                                :categories="localCategories"
                                :model.sync="currentPromo.Type"
                            ></PromoCategorySelectorLocal>
                        </v-col>
                    </v-row>

                    <v-text-field
                        outlined
                        v-model="currentPromo.Titolo"
                        label="Titolo"
                        required
                        :rules="textRules"
                        counter
                    ></v-text-field>

                    <v-textarea
                        outlined
                        v-model="currentPromo.Descrizione"
                        label="Testo Promo"
                        counter
                        rows="6"
                        required
                        :rules="textRules"
                    ></v-textarea>
                    <!-- <Html5Editor :content.sync="currentPromo.Descrizione" placeholder="Testo Promo" required :rules="textRules"></Html5Editor> -->
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <DateSelector
                        :formDates.sync="inizioValidita"
                        :label="'Inizio Validità'"
                        required
                    ></DateSelector>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                    <DateSelector
                        :formDates.sync="fineValidita"
                        :label="'Fine Validità'"
                        required
                    ></DateSelector>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" sm="6" md="6" lg="6">
                    <DateSelector
                        :formDates.sync="inizioPubblicazione"
                        :label="'Inizio Pubblicazione'"
                        :disabled="!publishDateCustom"
                        required
                    ></DateSelector>
                </v-col>

                <v-col cols="12" sm="6" md="6" lg="6">
                    <DateSelector
                        :formDates.sync="finePubblicazione"
                        :label="'Fine Pubblicazione'"
                        :disabled="!publishDateCustom"
                        required
                    ></DateSelector>
                </v-col>
            </v-row>
        </v-form>
    </v-container>
</template>

<script>
// export const Html5Editor = () => import("../../../components/core/Html5Editor.vue")

export const DateSelector = () =>
    import("../../../components/core/DateSelector");

export const PromoCategorySelector = () =>
    import("../../../components/core/PromoCategorySelector");

    export const PromoCategorySelectorLocal = () =>
    import("../../../components/core/PromoCategorySelectorLocal");

export const PromoPrioritySelector = () =>
    import("../../../components/core/PromoPrioritySelector");

import { downloadFileAsync } from "../../../utils/utils";
import * as promoCategoryActions from "../../../store/actions/promoCategory";
import { mapActions } from "vuex";

export default {
    props: {
        model: {
            type: Object,
            required: true
        },
        farms: {
            type: Array,
            required: false,
            default: null
        }
    },

    components: {
        DateSelector,
        PromoCategorySelector,
        PromoPrioritySelector,
        PromoCategorySelectorLocal
        // Html5Editor
    },

    data: () => ({
        publishDateCustom: false,
        valid: false,
        currentPromo: {},
        requiredFileRules: [
            value => !!value,
            value =>
                !value ||
                value.size < 50000000 ||
                "La dimensione deve essere inferiore a 50 MB!"
        ],
        fileRules: [
            value =>
                !value ||
                value.size < 50000000 ||
                "La dimensione deve essere inferiore a 50 MB!"
        ],
        textRules: [v => !!v || "Valore Richiesto", v => v.length > 7],
        hasVideo: false,
        hasAudio: false,
        localCategories: []
    }),

    watch: {
        currentPromo: {
            handler: function(newVal) {
                this.$emit("update:model", this.currentPromo);
            },
            immediate: true,
            deep: true
        },
        publishDateCustom: {
            handler: function(newVal) {
                if (newVal === false) {
                    this.currentPromo.InizioPubblicazione = this.currentPromo.InizioValidita
                    this.currentPromo.FinePubblicazione = this.currentPromo.FineValidita
                }
            },
            immediate: true,
            deep: true
        },
        farms: {
            handler: async function(newVal) {
                let ids = newVal.map(x => x.IDConsorzio);
                let cats = [];
                for(let i = 0; i < ids.length; i++) {
                    const result = await this.getCategories(ids[i]);
                    // console.log(result)
                    if (i === 0) cats = result;
                    else {
                        // console.log(cats.length, result.length)
                        const common = cats.filter(x => result.some(y => y.IdCategory === x.IdCategory));
                        cats = common;
                    }
                // console.log(cats)
                }
                this.localCategories = cats;
            },
            immediate: true,
            deep: true
        },
        "currentPromo.videoFile": {
            handler: function(newVal) {
                if (newVal !== null && newVal !== undefined) {
                    this.hasVideo = true;
                } else {
                    this.hasVideo = false;
                }
                this.$nextTick(function() {
                    this.$refs.form.validate();
                });
            },
            immediate: true
        },
        "currentPromo.audioFile": {
            handler: function(newVal) {
                if (newVal !== null && newVal !== undefined) {
                    this.hasAudio = true;
                } else {
                    this.hasAudio = false;
                }
                this.$nextTick(function() {
                    this.$refs.form.validate();
                });
            },
            immediate: true
        },
        valid: {
            handler: function(newVal) {
                this.$emit("update:valid", newVal);
            }
        }
    },

    computed: {
        inizioValidita: {
            get() {
                return this.currentPromo.InizioValidita.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.InizioValidita = `${newValue}T00:00:00Z`;
                if (!this.publishDateCustom) {
                    this.currentPromo.InizioPubblicazione = this.currentPromo.InizioValidita
                }
            }
        },

        fineValidita: {
            get() {
                return this.currentPromo.FineValidita.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.FineValidita = `${newValue}T00:00:00Z`;
                if (!this.publishDateCustom) {
                    this.currentPromo.FinePubblicazione = this.currentPromo.FineValidita
                }
            }
        },
        inizioPubblicazione: {
            get() {
                return this.currentPromo.InizioPubblicazione.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.InizioPubblicazione = `${newValue}T00:00:00Z`;
            }
        },

        finePubblicazione: {
            get() {
                return this.currentPromo.FinePubblicazione.substring(0, 10);
            },
            set(newValue) {
                this.currentPromo.FinePubblicazione = `${newValue}T00:00:00Z`;
            }
        },

        audioValidation() {
            return [
                //value => this.hasVideo || this.hasAudio,
                value =>
                    !value ||
                    value.size < 50000000 ||
                    "La dimensione deve essere inferiore a 50 MB!"
            ];
        },

        videoValidation() {
            return [
                //value => this.hasAudio || this.hasVideo,
                value =>
                    !value ||
                    value.size < 50000000 ||
                    "La dimensione deve essere inferiore a 50 MB!"
            ];
        }
    },

    methods: {
        ...mapActions({
            getCategories: promoCategoryActions.PROMOCATEGORY_FETCHCATEGORIES
        }),
    },

    async beforeMount() {
        console.log(this.model);
        this.currentPromo = JSON.parse(JSON.stringify(this.model));

        if (this.model.audioFileUrl !== null) {
            console.log(this.model.audioFileUrl);
            this.currentPromo.audioFile = await downloadFileAsync(
                this.model.audioFileUrl,
                this.model.AudioName
            );
        }
        if (this.model.videoFileUrl !== null) {
            this.currentPromo.videoFile = await downloadFileAsync(
                this.model.videoFileUrl,
                this.model.VideoName
            );
        }
        if (this.farms !== null) {
            let ids = this.farms.map(x => x.IDConsorzio);
            let cats = [];
            for(let i = 0; i < ids.length; i++) {
                const result = await this.getCategories(ids[i]);
                console.log(result)
                if (i === 0) cats = result;
                else {
                    console.log(cats.length, result.length)
                    const common = cats.filter(x => result.some(y => y.IdCategory === x.IdCategory));
                    cats = common;
                }
                // console.log(cats)
            }
            this.localCategories = cats;
            // console.log(cats)
        }
    },

    mounted() {
        console.log(this.currentPromo);
        this.$refs.form.validate();
    }
};
</script>

<style></style>
